
export default {
    name: 'FeatureSectionElement',
    props: {
        feature: {
            type: Object,
            default: () => {},
        },
        textClass: {
            type: Array,
            default: () => [],
        },
        textCenter: Boolean,
        textCenterMobile: Boolean,
        container: Boolean,
        reverse: Boolean,
        fixHeight: Boolean,
        slim: Boolean,
        imageTop: Boolean,
        cover: Boolean,
        index: {
            type: Number,
            default: 0,
        },
        gap: {
            type: String,
            default: 'gap-0',
        },
    },
    computed: {
        smDevice() {
            return this.$store.getters["data/smDevice"]
        },
        backgroundSrc() {
            return this.feature.backgroundSrc ? `url(${this.feature.backgroundSrc})` : undefined
        },
        backgroundSrcMobile() {
            return this.feature.backgroundSrcMobile ? `url(${this.feature.backgroundSrcMobile})` : undefined
        },
        hasBackground() {
            return !!(this.backgroundSrc || this.backgroundSrcMobile)
        },
        sectionClass() {
            return {
                'md-max:aspect-w-3 md-max:aspect-h-4 bg-top': this.hasBackground || this.fixHeight,
            }
        },
        gridClass() {
            return [
                'lg:grid-cols-5',
                this.gap,
                {
                    'lg:h-98 xl:h-150 overflow-hidden': this.fixHeight,
                    // 'py-10 lg:py-0': this.fixHeight && this.container,
                    'md-max:h-full': this.hasBackground || this.fixHeight,
                },
            ]
        },
        col1Class() {
            return [
                'lg:col-span-2',
                !this.imageTop ? (this.reverse ? 'order-1' : 'order-2') : undefined,
                this.imageTop ? 'order-2' : 'order-1',
                this.index % 2 !== (this.reverse ? 1 : 0)
                    ? `lg:order-2 ${
                          this.textCenter || this.textCenterMobile
                              ? this.textCenter
                                  ? 'justify-center'
                                  : 'md-max:justify-center lg:justify-end'
                              : 'justify-start lg:justify-end'
                      }`
                    : `lg:order-1 ${
                          this.textCenter || this.textCenterMobile
                              ? this.textCenter
                                  ? 'justify-center'
                                  : 'md-max:justify-center lg:justify-start'
                              : 'justify-start'
                      }`,
                this.fixHeight ? 'h-inherit' : 'h-full',
                {
                    'p-16 lg:p-4': !this.container,
                    'my-5 lg:my-0 md-max:px-4': this.container && !this.imageTop,
                    'mb-5 lg:mb-0 md-max:px-4': this.container && this.imageTop,
                    'md-max:h-1/2 md-max:mt-auto': this.hasBackground,
                },
            ]
        },

        col2Class() {
            return [
                'lg:col-span-3',
                !this.imageTop ? (this.reverse ? 'order-1' : 'order-2') : undefined,
                this.imageTop ? 'order-1' : 'order-2',
                this.index % 2 !== (this.reverse ? 1 : 0)
                    ? `lg:order-1 ${this.textCenter ? 'lg:justify-center' : 'lg:justify-start'}`
                    : `lg:order-2 ${this.textCenter ? 'lg:justify-center' : 'lg:justify-end'}`,
                // this.reverse && !this.container ? 'pb-0' : 'pt-0',
                {
                    'p-7 lg:p-0': !this.container,
                    // 'mb-10': this.fixHeight && this.reverse && this.container,
                    'h-fit md:h-inherit': this.fixHeight && !this.hasBackground,
                    'lg:h-inherit': this.fixHeight && this.hasBackground,
                    'md-max:hidden': this.hasBackground,
                },
            ]
        },
    },
}
